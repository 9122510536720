import styles from './ResetPassword.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { Col, Row } from 'components/primitives/grid';
import { RichText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { BackTo } from 'components/primitives/links';
import ResetPasswordForm from './ResetPasswordForm';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StoreType } from 'behavior/settings';
import { PageTitle } from 'components/objects/pages';

const loginRoute = routesBuilder.forLogin();

const ResetPassword = ({ closedStore }) => (
  <>
    {closedStore && <BackTo to={loginRoute} className={`${linkStyles.hypBack} ${styles.hypBack}`} />}
    <PageTitle textKey="ChangePassword" asDocumentTitle={closedStore} />
    <div className={styles.description}>{<RichText textKey="ChangePassword_Description" />}</div>
    <Row>
      <Col md={closedStore ? null : 8} lg={closedStore ? null : 7}>
        <ResetPasswordForm className={closedStore ? null : styles.form} />
      </Col>
    </Row>
  </>
);

ResetPassword.propTypes = {
  closedStore: PropTypes.bool,
};

export default connect(
  ({ settings }) => ({ closedStore: settings.storeType && settings.storeType === StoreType.Closed }),
)(ResetPassword);

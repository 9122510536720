import { memo } from 'react';
import ResetPassword from 'components/objects/resetPassword/ResetPassword';
import ClosedStoreLayout from '../ClosedStoreLayout';

const ClosedStoreResetPassword = () => (
  <ClosedStoreLayout>
    <ResetPassword />
  </ClosedStoreLayout>
);

export default memo(ClosedStoreResetPassword);
